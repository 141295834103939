import { CommonModule } from "@angular/common";
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { LfnUpstreamEquipmentOverwriteComponent } from "../../components/lfn-flow/lfn-upstream-equipment-overwrite/lfn-upstream-equipment-overwrite.component";
import { TableComponent } from "../../components/common/table/table.component";
import { TitleTextPipe } from "../../custom.pipe";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { ApiService } from "../../api.service";
import { JlStructureComponent } from "../../components/common/jl-structure/jl-structure.component";
import { MapSd1Component } from "../map-sd1/map-sd1.component";
import { SmpConnectivitySd1Component } from "../smp-connectivity-sd1/smp-connectivity-sd1.component";


@Component({
  selector: "app-select-bjl-sd1",
  standalone: true,
  imports: [
    TableComponent,
    CommonModule,
    NgxSpinnerModule,
    TitleTextPipe,MapSd1Component,SmpConnectivitySd1Component,
    LfnUpstreamEquipmentOverwriteComponent,JlStructureComponent
  ],
  templateUrl: "./select-bjl-sd1.component.html",
  styleUrls: ["./select-bjl-sd1.component.scss"]
})

export class SelectBjlSd1Component implements OnInit {
  
  @Input() inputData : any;
  tableData: any;
  displayedColumns: string[] = [];
  dataSource!: MatTableDataSource<any>;
  showBJLTable: boolean = true;
  isLoading: boolean = false;
  showMessage: boolean = false;
  MessageToShow: any;
  select_jl_screen: boolean = false;
  select_smp_screen: boolean = false;
  show_smp_table: boolean = false;
  select_table = true;
  upstream_screen = false;
  @ViewChild(TableComponent)
  tableChildComponent!: TableComponent;
  @ViewChild(MapSd1Component)
  mapChildComponent!: MapSd1Component;
  smp_connectivity_flag: boolean = false;
  select_bjl_structure_payload: any;
  
  constructor(private toastr: ToastrService,
    private apiService: ApiService,
    private spinner: NgxSpinnerService,
    private router: Router) { 

  }

  ngOnInit() {

  }

  receiveTableEventUpstream(data:any) {
    this.inputData['selected_upstream_equipment'] = data;
    this.isLoading = true;
    this.spinner.show();
    this.apiService.selectUpstreamStructure(this.inputData).subscribe({
      next: (response) => {

            if (response.message === 'select bjl structure sd1') {
              this.showBJLTable = false;
              this.upstream_screen = false;
              this.inputData = response;
              this.select_smp_screen = true;
              this.show_smp_table = false;
              this.tableData = response;
              this.select_bjl_structure_payload = response;
            }            
            else {              
              if ('file_name' in response) {
                this.showBJLTable = false;
                this.upstream_screen = false;
                this.showMessage = true;
                this.MessageToShow = response;
              } else {
                this.toastr.error(response.message, 'Error Message');
                console.log('error');
              }
            }

            this.isLoading = false;
            this.spinner.hide();
            // window.alert(response.messgae);
      }
      ,
      error: (error) => {
            this.isLoading = false;
            this.spinner.hide();
            this.toastr.error(
              `The API returned Unsuccessfull response : ${error}`,
              'Error Message'
            );
            //window.alert(`The API returned Unsuccessfull response : ${error}`);
          },
    });
 }
  receiveTableEvent(data:any) {
    this.inputData['selected_bjl'] = data;
    this.isLoading = true;
    this.spinner.show()  
    this.apiService.selectBjlSD1(this.inputData).subscribe({
      next: (response) => {
            if (response.message === 'select smp structure') {
              this.showBJLTable = false;
              this.upstream_screen = false;
              this.inputData = response;
              this.select_smp_screen = true;
              this.show_smp_table = true;
              this.tableData = response;
            }
            else if (response.message == 'select bjl sd1') {
              this.showBJLTable = true;
              this.upstream_screen = false;
              this.inputData = response;
              this.tableData = response;
              this.tableChildComponent.initializeFunction(this.inputData);
            }
              else if (response.message == 'select upstream equipment sd1') {
              this.showBJLTable = false;
              this.upstream_screen = true;
              this.inputData = response;
              this.tableData = response;
              this.tableChildComponent.initializeFunction(this.inputData);
            }
            else {              
              if ('file_name' in response) {
                this.showBJLTable = false;
                this.showMessage = true;
                this.MessageToShow = response;
              } else {
                this.toastr.error(response.message, 'Error Message');
                console.log('error');
              }
            }

            this.isLoading = false;
            this.spinner.hide();
            // window.alert(response.messgae);
      }
      ,
      error: (error) => {
            this.isLoading = false;
            this.spinner.hide();
            this.toastr.error(
              `The API returned Unsuccessfull response : ${error}`,
              'Error Message'
            );
            //window.alert(`The API returned Unsuccessfull response : ${error}`);
          },
    });
  }

  callBack() {
    this.showBJLTable = false;
    this.upstream_screen = false;
    this.select_smp_screen = true;
    this.show_smp_table = false;
    this.select_bjl_structure_payload["message"] = "select bjl structure sd1";
    this.mapChildComponent.receiveTableEvent(this.select_bjl_structure_payload);
  }
  OnSelectProceedMap(data: any) {
    //this.inputData['selected_smp_structure'] = data;
    
    this.isLoading = true;
    this.spinner.show()  
    this.apiService.selectSmpStructure(this.inputData,this.inputData.message).subscribe({
      next: (response) => {
       
    
            if (response.message === 'select smp structure') {
              this.showBJLTable = false;
              this.upstream_screen = false;
              this.inputData = response;
              this.tableData = response;
              this.select_smp_screen = true;
              this.show_smp_table = true;
              //this.tableChildComponent.initializeFunction(this.inputData);
              this.mapChildComponent.receiveTableEvent(this.inputData);
            }
            else if (response.message == 'select bjl structure sd1') {
              this.showBJLTable = false;
              this.upstream_screen = false;
              this.inputData = response;
              this.tableData = response;
              this.select_smp_screen = true;
              this.show_smp_table = false;
              //this.select_bjl_structure_payload = response;
              //this.tableChildComponent.initializeFunction(this.inputData);
              this.mapChildComponent.receiveTableEvent(this.inputData);
            }
            else if (response.message == 'select new smp') {
              this.smp_connectivity_flag = true;
              this.select_jl_screen = false;
              this.select_smp_screen = false;
              this.upstream_screen = false;
              this.showBJLTable = false;
              this.tableData = response;
              this.inputData = response;
              }
            else {              
              if ('file_name' in response) {
                this.showBJLTable = false;
                this.showMessage = true;
                this.MessageToShow = response;
              } else {
                this.toastr.error(response.message, 'Error Message');
                console.log('error');
              }
            }

            this.isLoading = false;
            this.spinner.hide();
      }
      ,
      error: (error) => {
            this.isLoading = false;
            this.spinner.hide();
            this.toastr.error(
              `The API returned Unsuccessfull response : ${error}`,
              'Error Message'
            );
          },
    });
  }

  receiveTableEventStructure(data: any) {
    this.mapChildComponent.receiveTableEvent(data,'bjl_smp')
  }

  
  callBJL() {
    this.isLoading = true;
    this.spinner.show();
    this.apiService.bjlpathoverwriteSD1(this.inputData).subscribe({
        next: (response) => {
          if (response.message == 'select upstream equipment sd1') {
              this.smp_connectivity_flag = false;
              this.select_jl_screen = false;
              this.select_smp_screen = false;
              this.showBJLTable = false;
              this.upstream_screen = true;
              this.inputData = response;
              this.tableData = response;
              this.tableChildComponent.initializeFunction(this.inputData);
          } else {
            //this.showTable = false;
              this.toastr.error(
              `The API returned Unsuccessfull response`,
              'Error Message'
            );
          }

          this.isLoading = false;
          this.spinner.hide();
        },
        error: (error) => {
          this.isLoading = false;
          this.spinner.hide();
          this.toastr.error(
            `The API returned Unsuccessfull response : ${error}`,
            'Error Message'
          );
        },
      });
  }
}
